import React from "react";
import { experimentalStyled } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useSettings from "../hooks/useSettings";

const LogoRoot = experimentalStyled("svg")``;

const Logo = (props) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const name = t("app.name");
  return (
    <LogoRoot
      width={`${name.length * 15}px`}
      height="42px"
      viewBox={`0 0 ${name.length * 15} 42`}
      {...props}
    >
      <title>BJE</title>
      <defs>
        <path id="a" d="M16.7857444 41.687894H0V0h16.7857444z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <text
          fill={settings.theme === "LIGHT" ? "#000" : "#FFF"}
          fontFamily="Averta-Bold"
          fontSize="24"
          transform="translate(.021265 .128425)"
        >
          <tspan x="20.9787352" y="27.8715751">
            {name}
          </tspan>
        </text>
        <g transform="translate(.021265 .128425)">
          <path
            fill={settings.theme === "LIGHT" ? "#000" : "#FFF"}
            fillRule="nonzero"
            d="M14.752827 18.5634042c1.1227828 0 2.0329174.9440869 2.0329174 2.1093702 0 1.1649196-.9101346 2.1093703-2.0329174 2.1093703H2.01094365c-1.12207395 0-2.03220848-.9444507-2.03220848-2.1093703 0-1.1652833.91013453-2.1093702 2.03220848-2.1093702H14.752827Zm-4.343695-5.5502851c1.5817486-.7851019 2.7268595-2.4786373 2.7268595-4.40173691 0-2.65726808-2.1690122-4.76809357-4.75765031-4.76809357-2.58828366 0-4.616948 2.11082549-4.616948 4.76809357 0 1.91764241 1.17169188 3.52677391 2.58544835 4.40173691 2.2955379 1.2143978.62554028 4.6011048-1.69586982 3.2888422-2.71764468-1.4170398-4.66160414-4.3329768-4.66160414-7.69057911C-.01063242 3.85529434 3.74509016 0 8.37798678 0c4.63325102 0 8.38897362 3.85529434 8.38897362 8.61138219 0 3.41581191-1.9386432 6.36849371-4.7477267 7.75897541-1.9358079 1.2009368-4.06548019-1.9391072-1.6101017-3.3572385ZM1.43714778 31.2392714C.48590126 29.4966217.09640054 29.2012079-.01701186 27.9435169c.00106324-1.5756611 1.24399228-2.8257122 2.77895826-2.8257122h2.96148135c1.03169844 0 1.86705166.857864 1.86705166 1.9176424 0 1.056868-.83535322 1.88781-1.86705166 1.88781H4.39012319l3.87409678 6.8585457 4.09914953-6.8585457h-1.3340134c-1.03098963 0-1.86669726-.830942-1.86669726-1.88781 0-1.0597784.83570763-1.9176424 1.86669726-1.9176424h2.9618358c1.5353204 0 2.7786038 1.2500511 2.7786038 2.8257122 0 .4860501-.119083.9240773-.3285415 1.3257235L9.77473139 40.9286226c-.64113448 1.0208508-2.16050625 1.0754223-2.89910452-.0382-1.6154179-2.8875598-3.97971207-6.9796945-5.43847909-9.6511512Z"
          />
        </g>
      </g>
    </LogoRoot>
  );
};

export default Logo;
