import React, { Suspense, lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// Dashboard pages

const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));

const ConsultationList = Loadable(
  lazy(() => import("./pages/consultations/ConsultationList"))
);
const ConsultationDetails = Loadable(
  lazy(() => import("./pages/consultations/ConsultationDetails"))
);

const ChapterList = Loadable(
  lazy(() => import("./pages/chapters/ChapterList"))
);

const CategoriesList = Loadable(
  lazy(() => import("./pages/categories/CategoriesList"))
);

const NewsList = Loadable(lazy(() => import("./pages/news/NewsList")));

const NewsDetails = Loadable(lazy(() => import("./pages/news/NewsDetails")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "/consultations",
        children: [
          {
            path: "",
            element: <ConsultationList />,
          },
          {
            path: ":id",
            element: <ConsultationDetails />,
          },
        ],
      },
      {
        path: "/chapters",
        children: [
          {
            path: "",
            element: <ChapterList />,
          },
        ],
      },
      {
        path: "/categories",
        children: [
          {
            path: "",
            element: <CategoriesList />,
          },
        ],
      },
      {
        path: "/news",
        children: [
          {
            path: "",
            element: <NewsList />,
          },
          {
            path: ":id",
            element: <NewsDetails />,
          },
        ],
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
